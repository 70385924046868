var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Panel',{staticClass:"mt-3",attrs:{"header":"Cancelar/Suspender Reserva Cartão"}},[_c('div',{staticClass:"formgrid grid"},[_c('div',{staticClass:"field col-6 md:col-6"},[_c('Button',{staticClass:"mt-2 mr-2 p-button-sm p-button-secondary",attrs:{"label":"Voltar","icon":"pi pi-chevron-left"},on:{"click":function($event){return _vm.$router.back()}}})],1)]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate()}}},[_c('div',{staticClass:"p-fluid formgrid grid"},[_c('div',{staticClass:"field col-4 md:col-4"},[_c('label',{staticClass:"required",attrs:{"for":"tipo"}},[_vm._v("Tipo")]),_c('Dropdown',{class:{ 'p-invalid': _vm.submitted && _vm.v$.tipo.$invalid },attrs:{"id":"tipo","options":_vm.tipos,"optionLabel":"nome","required":"","placeholder":"Selecione um tipo"},on:{"change":function($event){return _vm.textoObservacao()}},model:{value:(_vm.v$.tipo.$model),callback:function ($$v) {_vm.$set(_vm.v$.tipo, "$model", $$v)},expression:"v$.tipo.$model"}}),(_vm.submitted && _vm.v$.tipo.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo Tipo é obrigatório ")]):_vm._e()],1),_c('div',{staticClass:"field col-4 md:col-4"},[_c('label',{staticClass:"required",attrs:{"for":"motivo"}},[_vm._v("Judicial")]),_c('Dropdown',{class:{ 'p-invalid': _vm.submitted && _vm.v$.motivo.$invalid },attrs:{"id":"motivo","options":[
              { nome: 'Sim', code: 'JUDICIAL' },
              { nome: 'Não', code: 'NENHUM' } ],"optionLabel":"nome","placeholder":"Selecione se é judicial ou não","required":""},on:{"change":function($event){return _vm.changeMotivo()}},model:{value:(_vm.v$.motivo.$model),callback:function ($$v) {_vm.$set(_vm.v$.motivo, "$model", $$v)},expression:"v$.motivo.$model"}}),(_vm.submitted && _vm.v$.motivo.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo Motivo é obrigatório ")]):_vm._e()],1),(_vm.isJudicial)?_c('div',{staticClass:"field col-4 md:col-4"},[_c('label',{staticClass:"required",attrs:{"for":"DataDocumento"}},[_vm._v("Data do documento")]),_c('Calendar',{class:{
              'p-invalid':
                _vm.submitted &&
                (_vm.v$.dataDocumento.$invalid ||
                  _vm.dataDocumentoInvalida ||
                  _vm.dataDocumentoPosteriorDataAtual),
            },attrs:{"id":"DataDocumento","showIcon":true,"dateFormat":"dd/mm/yy"},model:{value:(_vm.v$.dataDocumento.$model),callback:function ($$v) {_vm.$set(_vm.v$.dataDocumento, "$model", $$v)},expression:"v$.dataDocumento.$model"}}),(_vm.submitted && _vm.v$.dataDocumento.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo Data do Documento é obrigatório ")]):_vm._e(),(_vm.dataDocumentoInvalida)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo Data do Documento está incorreto. ")]):_vm._e(),(_vm.dataDocumentoPosteriorDataAtual)?_c('div',{staticClass:"p-error"},[_vm._v(" A Data do Documento está posterior a data de hoje. ")]):_vm._e()],1):_vm._e()]),(_vm.isJudicial)?_c('div',{staticClass:"p-fluid formgrid grid"},[_c('div',{staticClass:"field col-3 md:col-3"},[_c('label',{staticClass:"required",attrs:{"for":"numeroOficio"}},[_vm._v("Número do ofício")]),_c('InputText',{class:{
              'p-invalid':
                _vm.submitted && _vm.v$.historicoConsignacao.numeroOficio.$invalid,
            },attrs:{"id":"numeroOficio"},model:{value:(_vm.v$.historicoConsignacao.numeroOficio.$model),callback:function ($$v) {_vm.$set(_vm.v$.historicoConsignacao.numeroOficio, "$model", $$v)},expression:"v$.historicoConsignacao.numeroOficio.$model"}}),(
              _vm.submitted &&
              _vm.v$.historicoConsignacao.numeroOficio.required.$invalid
            )?_c('div',{staticClass:"p-error"},[_vm._v(" O campo Número de Ofício é obrigatório ")]):_vm._e()],1),_c('div',{staticClass:"field col-3 md:col-3"},[_c('label',{staticClass:"required",attrs:{"for":"numeroProcesso"}},[_vm._v("Número do processo")]),_c('InputText',{class:{
              'p-invalid':
                _vm.submitted && _vm.v$.historicoConsignacao.numeroProcesso.$invalid,
            },attrs:{"id":"numeroProcesso"},on:{"change":function($event){return _vm.textoObservacao()}},model:{value:(_vm.v$.historicoConsignacao.numeroProcesso.$model),callback:function ($$v) {_vm.$set(_vm.v$.historicoConsignacao.numeroProcesso, "$model", $$v)},expression:"v$.historicoConsignacao.numeroProcesso.$model"}}),(
              _vm.submitted &&
              _vm.v$.historicoConsignacao.numeroProcesso.required.$invalid
            )?_c('div',{staticClass:"p-error"},[_vm._v(" O campo Número de Processo é obrigatório ")]):_vm._e()],1),_c('div',{staticClass:"field col-3 md:col-3"},[_c('label',{staticClass:"required",attrs:{"for":"juizado"}},[_vm._v("Juizado")]),_c('InputText',{class:{
              'p-invalid':
                _vm.submitted && _vm.v$.historicoConsignacao.juizado.$invalid,
            },attrs:{"id":"juizado"},model:{value:(_vm.v$.historicoConsignacao.juizado.$model),callback:function ($$v) {_vm.$set(_vm.v$.historicoConsignacao.juizado, "$model", $$v)},expression:"v$.historicoConsignacao.juizado.$model"}}),(
              _vm.submitted && _vm.v$.historicoConsignacao.juizado.required.$invalid
            )?_c('div',{staticClass:"p-error"},[_vm._v(" O campo Juizado é obrigatório ")]):_vm._e()],1),_c('div',{staticClass:"field col-3 md:col-3"},[_c('label',{staticClass:"required",attrs:{"for":"nomeJuiz"}},[_vm._v("Nome do Juiz")]),_c('InputText',{class:{
              'p-invalid':
                _vm.submitted && _vm.v$.historicoConsignacao.nomeJuiz.$invalid,
            },attrs:{"id":"nomeJuiz"},model:{value:(_vm.v$.historicoConsignacao.nomeJuiz.$model),callback:function ($$v) {_vm.$set(_vm.v$.historicoConsignacao.nomeJuiz, "$model", $$v)},expression:"v$.historicoConsignacao.nomeJuiz.$model"}}),(
              _vm.submitted && _vm.v$.historicoConsignacao.nomeJuiz.required.$invalid
            )?_c('div',{staticClass:"p-error"},[_vm._v(" O campo Nome do Juiz é obrigatório ")]):_vm._e()],1)]):_vm._e(),_c('div',{staticClass:"p-fluid formgrid grid col-12"},[_c('div',{staticClass:"field md:col-12"},[_c('label',{staticClass:"required",attrs:{"for":"observacao"}},[_vm._v("Observação")]),_c('Textarea',{class:{
              'p-invalid':
                _vm.submitted && _vm.v$.historicoConsignacao.observacao.$invalid,
            },attrs:{"id":"observacao","autoResize":true},model:{value:(_vm.v$.historicoConsignacao.observacao.$model),callback:function ($$v) {_vm.$set(_vm.v$.historicoConsignacao.observacao, "$model", $$v)},expression:"v$.historicoConsignacao.observacao.$model"}}),_vm._v(" "),(
              _vm.submitted &&
              _vm.v$.historicoConsignacao.observacao.required.$invalid
            )?_c('div',{staticClass:"p-error"},[_vm._v(" O campo Observação é obrigatório ")]):_vm._e()],1)]),_c('Button',{staticClass:"md:col-2",attrs:{"type":"submit","label":"Salvar","icon":"pi pi-save"}})],1)]),_c('modal-senha-servidor',{attrs:{"exibir":_vm.exibirModalSenhaServidor,"metodoSalvar":_vm.salvar,"tipo":false},on:{"mudarVisibilidade":function($event){_vm.exibirModalSenhaServidor = $event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }